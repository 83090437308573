
import { Options, Vue } from "vue-class-component";
import TableV1 from "@/app/ui/components/table/index.vue";
import { LocationController } from "@/app/ui/controllers/LocationController";
import debounce from "lodash/debounce";
import { RegionCity, RegionData } from "@/domain/entities/Location";

@Options({
  components: {
    TableV1
  }
})
export default class TabsRegion extends Vue {
  // Breadcrumb
  breadcrumb = [];

  // Search
  search = "";
  onSearch(value: string) {
    LocationController.searchRegion(value);
    this.search = value;
    this.firstPage();
  }

  clearSearch() {
    LocationController.clearSearchRegion();
    this.search = "";
    this.firstPage();
  }

  // Filter
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];
  statusSelect = false;
  statusName = "";

  get statusRegion() {
    return LocationController.statusRegion;
  }

  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    this.onCloseStatusSelect();
    LocationController.selectStatusRegion(value);
    this.firstPage();
    this.statusName = name;
  }

  // Header
  columns = [
    {
      name: "No.",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      render: (item: RegionData, index: number) => {
        return `<div class='text-left text-gray-lp-300'>${index +
          1 +
          this.pagination.limit * (this.pagination.page - 1)}</div>`;
      }
    },
    {
      name: "Region Code",
      key: "id",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: RegionData) => {
        return `<span class='text-gray-lp-300'>${item.regionCode}</span>`;
      }
    },
    {
      name: "Region Group Name",
      key: "name",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      render: (item: RegionData) => {
        return `<span class='text-gray-lp-300'>${item.regionName}</span>`;
      }
    },
    {
      name: "City Code List",
      key: "listCityCode",
      styleHead: "w-1/3 text-left whitespace-no-wrap",
      styleBody: "text-center rounded px-2 py-0 flex justify-start",
      render: (item: RegionData) => {
        return `<div class='flex justify-start gap-4 flex-wrap'>${
          item.regionCities?.length
            ? item.regionCities.map(
                (city: RegionCity) =>
                  "<div class='rounded px-2 py-0 bg-gray-lp-200 font-medium text-black-lp-300'>" +
                  city.city.code +
                  "</div>"
              )
            : "-"
        }</div>`.replaceAll(",", "");
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-1/12 text-left",
      styleBody: "",
      render: (item: RegionData) => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${
          item.regionStatus.toLowerCase() === "active"
            ? "bg-green-lp-300"
            : "bg-red-300"
        } ${
          item.regionStatus.toLowerCase() === "active"
            ? "text-green-lp-200"
            : "text-red-lp-200"
        }"
      ><span class="capitalize font-medium">${item.regionStatus.toLowerCase()}</span></div></div>`;
      }
    }
  ];

  created() {
    LocationController.setSelectStatusRegion("");
    LocationController.setSearchRegion("");
    this.getListData();
    this.firstPage();
  }

  getListData() {
    LocationController.getRegionList({
      search: this.search,
      status: this.statusRegion,
      page: this.page,
      limit: this.limit
    });
  }

  firstPage() {
    return LocationController.setFirstPageRegion();
  }

  get regionListData() {
    return LocationController.regionData.regionData;
  }

  //Loading
  get isLoading() {
    return LocationController.isLoadingRegion;
  }

  // Pagination
  get pagination() {
    return LocationController.regionData.pagination;
  }
  get page() {
    return LocationController.pageRegion;
  }
  get limit() {
    return LocationController.limit;
  }
  nextPage() {
    LocationController.setNextPageRegion();
  }

  prevPage() {
    LocationController.setPrevPageRegion();
  }

  toPage = debounce((value: number) => {
    LocationController.setPageRegionAction(value);
  }, 500);

  //Error
  get isError() {
    return LocationController.isError;
  }

  //Error Cause
  get errorCause() {
    return LocationController.isErrorCauseRegion;
  }
}

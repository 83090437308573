
import { Options, Vue } from "vue-class-component";
import { LocationController } from "@/app/ui/controllers/LocationController";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { ROUTE_LOCATION_COVERAGE } from "@/app/infrastructures/misc/RolePermission";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
@Options({
  components: {},
  computed: {
    id() {
      return this.$route.params.id;
    },
    isEditAble() {
      return checkRolePermission(ROUTE_LOCATION_COVERAGE.EDIT);
    }
  }
})
export default class DetailCity extends Vue {
  created() {
    this.getDetailCityData(this.$route.params.id);
  }

  fetchCityData() {
    this.getDetailCityData(this.$route.params.id);
  }

  getDetailCityData(id: any) {
    return LocationController.getDetailCity({ id });
  }

  get dataDetail() {
    return LocationController.cityDetail;
  }

  goBack() {
    this.$router.push(`/network/location`);
  }

  item = {
    status: "Inactive"
  };

  goEdit(id: string) {
    this.$router.push(`/network/location/city/edit/${id}`);
  }

  //Loading
  public get isLoading() {
    return LocationController.isLoadingDetailCity;
  }

  formatDate(date: string) {
    return formatDate(date);
  }

  //Error
  get isError() {
    return LocationController.isError;
  }

  //Error Cause
  get errorCause() {
    return LocationController.isErrorCauseCity;
  }
}

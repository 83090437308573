
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import { LocationController } from "@/app/ui/controllers/LocationController";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";

@Options({
  components: {
    Input
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.isError) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      LocationController.setOpenModalSuccess(false);
      next();
    }
  }
})
export default class Edit extends Vue {
  created() {
    this.getDetailCityData(this.$route.params.id);
  }

  id() {
    return this.$route.params.id;
  }

  goBack() {
    this.$router.back();
  }

  fetchCityData() {
    this.getDetailCityData(this.$route.params.id);
  }

  item = {
    status: "Active"
  };

  isDisabled = true;

  // Filter
  statusData = [
    { name: "WIB", value: "wib" },
    { name: "WITA", value: "wita" },
    { name: "WIT", value: "wit" }
  ];
  statusSelect = false;
  statusName = "WIB";

  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string) {
    this.onCloseStatusSelect();
    this.statusName = name;
  }

  // Detail data
  getDetailCityData(id: any) {
    return LocationController.getDetailCity({ id });
  }

  createdAt = "";
  createdBy = "";
  updateAt = "";
  updateBy = "";

  dataDetail() {
    const detail = LocationController.cityDetail;
    switch (detail.timezone) {
      case "GMT+7:00":
        this.statusName = "WIB";
        break;
      case "GMT+8:00":
        this.statusName = "WITA";
        break;
      case "GMT+9:00":
        this.statusName = "WIT";
        break;
    }
    this.createdAt = formatDate(detail.createdAt);
    this.createdBy = detail.createdBy;
    this.updateAt =
      detail.updateAt === "0001-01-01T00:00:00Z"
        ? formatDate(detail.createdAt)
        : formatDate(detail.updateAt);
    this.updateBy = detail.updatedBy;
    return detail;
  }

  // Toggle Route
  openRoute = false;
  onOpenRoute() {
    if (this.isRoute) {
      this.openRoute = true;
    } else {
      LocationController.setRoute(!this.isRoute);
    }
  }
  onCloseRoute() {
    this.openRoute = false;
  }
  get isRoute() {
    return LocationController.isRoute;
  }
  changeRoute() {
    LocationController.setRoute(!this.isRoute);
    this.openRoute = false;
    this.messageSuccess = `Lokasi “${this.dataDetail().name} - ${
      this.dataDetail().code
    }”`;
  }
  cacheRoute() {
    return LocationController.cacheRoute;
  }
  routeForComparison() {
    return this.isRoute;
  }

  // Toggle Free Trade Zone
  get isFreeTradeZone() {
    return LocationController.isFreeTradeZone;
  }
  changeFreeTradeZone() {
    LocationController.setFreeTradeZone(!this.isFreeTradeZone);
  }
  cacheFreeTradeZone() {
    return LocationController.cacheFreeTradeZone;
  }
  freeTradeZoneForComparison() {
    return this.isFreeTradeZone;
  }

  // Modal Success
  messageSuccess = "";
  get openSuccess() {
    return LocationController.openModalSuccess;
  }
  onCloseSuccess() {
    LocationController.refreshES();
    this.$router.push(`/network/location/city/${this.$route.params.id}`);
  }

  // Save data
  openSave() {
    return LocationController.openModal;
  }
  onOpenSave() {
    LocationController.setOpenModal(true);
  }
  onCloseSave() {
    LocationController.setOpenModal(false);
  }

  get isSundayDelivery() {
    return LocationController.isSundayDelivery;
  }

  changeSundayDelivery() {
    LocationController.setIsSundayDelivery(!this.isSundayDelivery);
  }

  _onEdit() {
    LocationController._onEditCity({
      id: Number(this.id()),
      isActive: this.isRoute ? "active" : "inactive",
      freeTradeZone: this.isFreeTradeZone ? "yes" : "no",
      sundayDelivery: this.isSundayDelivery ? "1" : "0"
    });
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  get hasChanged() {
    return (
      this.cacheRoute() !== this.routeForComparison() ||
      this.cacheFreeTradeZone() !== this.freeTradeZoneForComparison()
    );
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  //Error
  get isError() {
    return LocationController.isError;
  }

  //Error Cause
  get errorCause() {
    return LocationController.isErrorCauseCity;
  }

  handleErrorClose() {
    LocationController.handleError();
    this.getDetailCityData(this.$route.params.id);
  }

  //Loading
  public get isLoading() {
    return LocationController.isLoadingDetailCity;
  }
}

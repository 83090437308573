<template>
  <div class="flex flex-col font-montserrat overflow-hidden h-screen relative">
    <Header
      :isSearchable="true"
      :isSelectStatus="true"
      placeholder="Cari nama lokasi"
      :searchValue="search"
      :searchAction="onSearch"
      :clearSearch="clearSearch"
      :statusData="statusData"
      :onOpenStatusSelect="onOpenStatusSelect"
      :onCloseStatusSelect="onCloseStatusSelect"
      :onSelectStatus="onSelectStatus"
      :valueStatus="statusName"
      :statusSelect="statusSelect"
    />
    <TableLazyLoad v-show="isLoading" :columns="columns" />
    <ErrorMessage
      v-show="isError && !isLoading"
      :errorType="errorCause"
      :onTryAgain="getListData"
    />
    <div
      v-if="
        districtListData.length === 0 && !isLoading && page === 1 && !isError
      "
      class="w-full h-full bg-white justify-center items-center flex flex-col mx-auto py-20 text-12px xxl:text-14px overflow-hidden"
    >
      <img
        src="@/app/ui/assets/svg/data-not-found.svg"
        class="w-32 mt-8 mx-auto"
        alt="not_found"
      />
      <p class="text-gray-lp-300 py-2 font-bold">
        Tidak ada hasil yang ditemukan
      </p>
      <p class="text-gray-lp-300 py-2">
        Apa yang anda cari tidak ditemukan atau tidak ada
      </p>
    </div>
    <div
      v-if="
        districtListData.length === 0 && !isLoading && page !== 1 && !isError
      "
      class="w-full h-full bg-white justify-center items-center flex flex-col mx-auto py-20 text-12px xxl:text-14px overflow-hidden border-t border-gray-lp-200"
    >
      <img
        src="@/app/ui/assets/svg/data-not-found.svg"
        class="w-32 mt-8 mx-auto"
        alt="not_found"
      />
      <p class="text-gray-lp-300 py-2 font-bold">
        Data tidak ditemukan pada halaman ini
      </p>
      <div class="absolute w-full bottom-0">
        <Pagination
          :onNext="nextPage"
          :onPrevious="prevPage"
          :page="page"
          :toPage="toPage"
        />
      </div>
    </div>
    <div
      class="overflow-y-auto h-screen pb-20"
      v-show="!isLoading && districtListData.length !== 0 && !isError"
    >
      <TableV2
        :columns="columns"
        :data="districtListData"
        class="my-2"
        @click="onClickRow"
        :isDetailAble="isDetailAble"
      />
    </div>
    <div
      class="absolute w-full bottom-0"
      v-show="!isLoading && districtListData.length !== 0 && !isError"
    >
      <Pagination
        :onNext="nextPage"
        :onPrevious="prevPage"
        :page="page"
        :isButtonNext="districtListData.length >= limit"
        :totalData="districtListData.length"
        :toPage="toPage"
      />
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import EmptyList from "@/app/ui/components/empty-list/index.vue";
import TableV1 from "@/app/ui/components/table/index.vue";
import router from "@/app/ui/router";
import { LocationController } from "@/app/ui/controllers/LocationController";
import debounce from "lodash/debounce";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { ROUTE_LOCATION_COVERAGE } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    EmptyList,
    TableV1
  },
  computed: {
    isDetailAble() {
      return checkRolePermission(ROUTE_LOCATION_COVERAGE.DETAIL);
    }
  }
})
export default class TabsDistrict extends Vue {
  // Breadcrumb
  breadcrumb = [];

  // Search
  search = "";
  onSearch(value) {
    LocationController.searchDistrict(value);
    this.search = value;
    this.firstPage();
  }

  clearSearch() {
    LocationController.clearSearchDistrict();
    this.search = "";
    this.firstPage();
  }

  // Filter
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];
  statusSelect = false;
  statusName = "";

  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name, value) {
    this.onCloseStatusSelect();
    LocationController.selectStatusDistrict(value);
    this.firstPage();
    this.statusName = name;
  }

  // Header
  columns = [
    {
      name: "Kecamatan ID",
      key: "id",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      render: item => {
        return `<span class='text-black-lp-300 flex justify-center'>${item.id}</span>`;
      }
    },
    {
      name: "Kode kecamatan",
      key: "id",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      render: item => {
        return `<span class='text-black-lp-300 flex justify-center'>${item.code}</span>`;
      }
    },
    {
      name: "Kode ursa",
      key: "ursaCode",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      styleBody: "text-center rounded px-2 py-0",
      render: item => {
        return `<div class='flex justify-center'><div class=' text-black-lp-300 font-medium ${ item.ursaCode ? 'rounded px-2 py-0 bg-gray-lp-400' : '' }'>${
          !item.ursaCode ? "-" : item.ursaCode
        }</div></div>`;
      }
    },
    {
      name: "Nama kecamatan",
      key: "districtName",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: item => {
        return `<span class="text-black-lp-300">${item.name}</span>`;
      }
    },
    {
      name: "Kode pos",
      key: "kodePos",
      styleHead: "w-4/20 text-left whitespace-no-wrap",
      styleBody: "",
      render: item => {
        return `<div class='flex flex-wrap gap-2 text-black-lp-300'>
          ${item.destinationListZipCode.length ? `${item.destinationListZipCode.map((zip, index) =>
            index === 3 && item.destinationListZipCode.length > 4 && !item.showMore?
            `<div class='rounded px-2 py-0 bg-gray-lp-400 min-w-min text-center font-medium'>
               +${item.destinationListZipCode.length - 3} more
             </div>`: 
             index < 4 || item.showMore ?
            `<div class='rounded px-2 py-0 bg-gray-lp-400 min-w-min text-center font-medium pointer-events-none'>
                ${zip}
            </div> ${item.destinationListZipCode.length > 4 && item.showMore && index === item.destinationListZipCode.length - 1 ? 
            `<div class='rounded px-2 py-0 bg-gray-lp-400 min-w-min text-center font-medium'>Show less</div>` : 
            ''}` :
            ""
          ).join('')}`: "-"} 
        </div>`
      },
      onClickValue: (item) => {
        item.showMore = !item.showMore
      }
    },
    {
      name: "Kota",
      key: "city",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: item => {
        return `<span class="capitalize text-black-lp-300">${item.cityName.toLowerCase()}</span>`;
      }
    },
    {
      name: "Tipe kota",
      key: "type",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: item => {
        return `<span class="capitalize whitespace-no-wrap text-center text-black-lp-300">${item.type}</span>`;
      }
    },
    {
      name: "Kode vendor",
      key: "vendorCode",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: item => {
        return `<span class="capitalize whitespace-no-wrap text-center text-black-lp-300">${
          item.vendorCode !== "" ? item.vendorCode : "-"
        }</span>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: item => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${
          item.status.toLowerCase() === "active"
            ? "bg-green-lp-300"
            : "bg-red-300"
        } ${
          item.status.toLowerCase() === "active"
            ? "text-green-lp-200"
            : "text-red-lp-200"
        }"
      ><span class="capitalize">${item.status.toLowerCase() === 'active' ? "Aktif" : "Tidak Aktif"}</span></div></div>`;
      }
    }
  ];

  created() {
    LocationController.setSelectStatusDistrict("");
    LocationController.setSearchDistrict("");
    this.getListData();
    this.firstPage();
  }

  getListData() {
    LocationController.getDistrictList({
      search: "",
      status: "",
      page: 1,
      limit: 20,
      cache: false
    });
  }

  firstPage() {
    return LocationController.setFirstDistrictPage();
  }

  get districtListData() {
    return LocationController.districtData.districtData;
  }

  //Loading
  get isLoading() {
    return LocationController.isLoadingDistrict;
  }

  // To Detail
  onClickRow(item) {
    this.breadcrumb.push(item.id);
    router.push({
      path: `/network/location/district/${item.id}`,
      state: {
        fullName: `${item.name} - ${item.districtCode}`,
        id: item.id
      }
    });
  }

  // Pagination
  get page() {
    return LocationController.pageDistrict;
  }
  get limit() {
    return LocationController.limit;
  }
  nextPage() {
    LocationController.setNextPageDistrict();
  }

  prevPage() {
    LocationController.setPrevPageDistrict();
  }

  toPage = debounce(value => {
    LocationController.setPageDistrictAction(value);
  }, 500);

  //Error
  get isError() {
    return LocationController.isError;
  }

  //Error Cause
  get errorCause() {
    return LocationController.isErrorCauseDistrict;
  }
}
</script>


import { Options, Vue } from "vue-class-component";
import { LocationController } from "@/app/ui/controllers/LocationController";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import { ROUTE_LOCATION_COVERAGE } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {},
  computed: {
    id() {
      return this.$route.params.id;
    },
    isEditAble() {
      return checkRolePermission(ROUTE_LOCATION_COVERAGE.EDIT);
    }
  }
})
export default class DetailDistrict extends Vue {
  created() {
    this.getDetailDistrictData(this.$route.params.id);
  }

  fetchDistrictData() {
    this.getDetailDistrictData(this.$route.params.id);
  }

  goBack() {
    this.$router.push(`/network/location`);
  }

  getDetailDistrictData(id: any) {
    return LocationController.getDetailDistrict({ id });
  }

  get dataDetail() {
    return LocationController.districtDetail;
  }

  //Loading
  public get isLoading() {
    return LocationController.isLoadingDetail;
  }

  formatDate(date: string) {
    return formatDate(date);
  }

  item = {
    status: "Inactive"
  };

  goEdit(id: string) {
    this.$router.push(`/network/location/district/edit/${id}`);
  }

  //Error
  get isError() {
    return LocationController.isError;
  }

  //Error Cause
  get errorCause() {
    return LocationController.isErrorCauseDistrict;
  }
}

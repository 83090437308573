
import { Options, Vue } from "vue-class-component";
import TabsCity from "./component/city/tabs-city.vue";
import TabsDistrict from "./component/district/tabs-district.vue";
import TabsCountry from "./component/country/tabs-country.vue";
import TabsRegion from "./component/region/tabs-region.vue";

@Options({
  components: {
    TabsCity,
    TabsDistrict,
    TabsCountry,
    TabsRegion
  }
})
export default class Location extends Vue {
  // Tabs
  tabs = [
    { name: "TabsCountry", title: "Country" },
    { name: "TabsRegion", title: "Region" },
    { name: "TabsCity", title: "City" },
    { name: "TabsDistrict", title: "District" }
  ];
  currentTab = "TabsCountry";

  changeTabs(value: string) {
    this.currentTab = value;
  }
}

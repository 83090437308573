
import { Options, Vue } from "vue-class-component";
import { LocationController } from "@/app/ui/controllers/LocationController";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { ROUTE_LOCATION_COVERAGE } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {},
  computed: {
    id() {
      return this.$route.params.id;
    },
    isEditAble() {
      return checkRolePermission(ROUTE_LOCATION_COVERAGE.EDIT);
    }
  }
})
export default class Detail extends Vue {
  goBack() {
    this.$router.push(`/network/location`);
  }

  goEdit(id: string) {
    this.$router.push(`/network/location/country/edit/${id}`);
  }

  created() {
    this.getDetailCountryData(this.$route.params.id);
  }

  fetchCountryData() {
    this.getDetailCountryData(this.$route.params.id);
  }

  getDetailCountryData(id: any) {
    return LocationController.getDetailCountry({ id });
  }

  dataDetail() {
    return LocationController.countryDetail;
  }

  //Loading
  public get isLoading() {
    return LocationController.isLoadingDetailCountry;
  }

  formatDate(date: string) {
    return formatDate(date);
  }

  //Error
  get isError() {
    return LocationController.isError;
  }

  //Error Cause
  get errorCause() {
    return LocationController.isErrorCauseCountry;
  }
}

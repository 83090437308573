
import { Options, Vue } from "vue-class-component";
import TableV1 from "@/app/ui/components/table/index.vue";
import router from "@/app/ui/router";
import { LocationController } from "@/app/ui/controllers/LocationController";
import debounce from "lodash/debounce";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { ROUTE_LOCATION_COVERAGE } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    TableV1
  },
  computed: {
    isDetailAble() {
      return checkRolePermission(ROUTE_LOCATION_COVERAGE.DETAIL);
    }
  }
})
export default class TabsCity extends Vue {
  // Breadcrumb
  breadcrumb: Array<string> = [];

  // Search
  search = "";
  onSearch(value: string) {
    LocationController.searchCity(value);
    this.search = value;
    this.getFirstCityPage();
  }

  public clearSearch() {
    LocationController.clear();
    this.getFirstCityPage();
    this.search = "";
  }

  //Loading
  public get isLoading() {
    return LocationController.isLoading;
  }

  // Filter
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];
  statusSelect = false;
  statusName = "";

  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    LocationController.selectStatusCity(value);
    this.onCloseStatusSelect();
    this.getFirstCityPage();
    this.statusName = name;
  }

  // Header
  columns = [
    {
      name: "City ID",
      key: "id",
      styleHead: "w-1/12 text-center whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: any) => {
        return `<span class='text-red-lp-100 flex justify-center'>${item.id}</span>`;
      }
    },
    {
      name: "City Code",
      key: "code",
      styleHead: "w-2/12 text-center",
      styleBody: "text-center rounded px-2 py-0",
      render: (item: any) => {
        return `<div class='flex justify-center'><div class='rounded px-2 py-0 bg-gray-lp-400 text-black-lp-300'>${item.code}</div></div>`;
      }
    },
    {
      name: "City Name",
      key: "name",
      styleHead: "w-2/12 text-left",
      styleBody: "",
      render: (item: any) => {
        return `<span class="capitalize text-black-lp-300">${item.name.toLowerCase()}</span>`;
      }
    },
    {
      name: "Origin Transit",
      key: "name",
      styleHead: "w-2/12 text-left",
      styleBody: "",
      render: (item: any) => {
        return `<span class="capitalize text-black-lp-300">
        ${
          item.originTransit !== "" ? item.originTransit.toLowerCase() : "-"
        }</span>`;
      }
    },
    {
      name: "Destination Transit",
      key: "name",
      styleHead: "w-2/12 text-left",
      styleBody: "",
      render: (item: any) => {
        return `<span class="capitalize text-black-lp-300">
        ${
          item.destinationTransit !== ""
            ? item.destinationTransit.toLowerCase()
            : "-"
        }</span>`;
      }
    },
    {
      name: "Timezone",
      key: "timezone",
      styleHead: "w-2/12 text-left",
      styleBody: "",
      render: (item: any) => {
        return `<span class="mx-2 text-black-lp-300">${item.timezone}</span>`;
      }
    },
    {
      name: "Free Trade Zone",
      key: "freeTradeZone",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: any) => {
        return `<span class="mx-10 capitalize text-black-lp-300">${item.freeTradeZone}</span>`;
      }
    },
    {
      name: "Status",
      key: "isActive",
      styleHead: "w-3/12 text-left",
      styleBody: "",
      render: (item: any) => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${
          item.isActive.toUpperCase() === "ACTIVE"
            ? "bg-green-lp-300"
            : "bg-red-300"
        } ${
          item.isActive.toUpperCase() === "ACTIVE"
            ? "text-green-lp-200"
            : "text-red-lp-200"
        }"
      ><span class="capitalize">${item.isActive.toLowerCase()}</span></div></div>`;
      }
    }
  ];

  // get data city list
  created() {
    LocationController.selectStatus("");
    LocationController.setSearchCity("");
    this.getFirstCityPage();
    this.getListData();
  }

  getListData() {
    LocationController.getCityList({
      search: "",
      status: "",
      page: 1,
      limit: 10
    });
  }

  getFirstCityPage() {
    LocationController.setFirstCityPage();
  }

  get cityListData() {
    return LocationController.cityData.cityData;
  }

  // To Detail
  onClickRow(item: any) {
    this.breadcrumb.push(item.id);
    router.push({
      path: `/network/location/city/${item.id}`,
      state: {
        fullName: `${item.name} - ${item.cityCode}`,
        id: item.id
      }
    });
  }

  // Pagination
  public get page() {
    return LocationController.pageCity;
  }
  public get limit() {
    return LocationController.limit;
  }
  nextPage() {
    LocationController.setNextPageCity();
  }

  prevPage() {
    LocationController.setPrevPageCity();
  }
  toPage = debounce(value => {
    LocationController.setPageCityAction(value);
  }, 500);

  //Error
  get isError() {
    return LocationController.isError;
  }

  //Error Cause
  get errorCause() {
    return LocationController.isErrorCauseCity;
  }
}

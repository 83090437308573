<template>
  <div class="flex flex-col font-montserrat overflow-hidden relative h-screen">
    <Header
      :isSearchable="true"
      :isSelectStatus="true"
      placeholder="Cari nama lokasi"
      :searchValue="search"
      :searchAction="onSearch"
      :clearSearch="clearSearch"
      :statusData="statusData"
      :onOpenStatusSelect="onOpenStatusSelect"
      :onCloseStatusSelect="onCloseStatusSelect"
      :onSelectStatus="onSelectStatus"
      :valueStatus="statusName"
      :statusSelect="statusSelect"
    />
    <TableLazyLoad v-show="isLoading" :columns="columns" />
    <ErrorMessage
      v-show="isError && !isLoading"
      :errorType="errorCause"
      :onTryAgain="getListData"
    />
    <div
      v-if="
        countryListData.length === 0 && !isLoading && page === 1 && !isError
      "
      class="w-full h-full bg-white justify-center items-center flex flex-col mx-auto py-20 text-12px xxl:text-14px overflow-hidden"
    >
      <img
        src="@/app/ui/assets/svg/data-not-found.svg"
        class="w-32 mt-8 mx-auto"
        alt="not_found"
      />
      <p class="text-gray-lp-300 py-2 font-bold">
        Tidak ada hasil yang ditemukan
      </p>
      <p class="text-gray-lp-300 py-2">
        Apa yang anda cari tidak ditemukan atau tidak ada
      </p>
    </div>
    <div
      v-if="
        countryListData.length === 0 && !isLoading && !isError && page !== 1
      "
      class="w-full h-full bg-white justify-center items-center flex flex-col mx-auto py-20 text-12px xxl:text-14px overflow-hidden border-t border-gray-lp-200"
    >
      <img
        src="@/app/ui/assets/svg/data-not-found.svg"
        class="w-32 mt-8 mx-auto"
        alt="not_found"
      />
      <p class="text-gray-lp-300 py-2 font-bold">
        Data tidak ditemukan pada halaman ini
      </p>
      <div class="absolute w-full bottom-0">
        <Pagination
          :onNext="nextPage"
          :onPrevious="prevPage"
          :page="page"
          :toPage="toPage"
        />
      </div>
    </div>
    <div
      class="overflow-y-auto h-screen w-full pb-12"
      v-show="!isLoading && countryListData.length !== 0 && !isError"
    >
      <TableV1
        :columns="columns"
        :data="countryListData"
        class="my-2"
        :isDetailAble="isDetailAble"
        @click="onClickRow"
      />
    </div>
    <div
      class="absolute w-full bottom-0"
      v-show="!isLoading && countryListData.length !== 0 && !isError"
    >
      <Pagination
        :onNext="nextPage"
        :onPrevious="prevPage"
        :page="page"
        :isButtonNext="countryListData.length >= limit"
        :totalData="countryListData.length"
        :toPage="toPage"
      />
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import EmptyList from "@/app/ui/components/empty-list/index.vue";
import TableV1 from "@/app/ui/components/table/index.vue";
import router from "@/app/ui/router";
import { LocationController } from "@/app/ui/controllers/LocationController";
import debounce from "lodash/debounce";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { ROUTE_LOCATION_COVERAGE } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    EmptyList,
    TableV1
  },
  computed: {
    isDetailAble() {
      return checkRolePermission(ROUTE_LOCATION_COVERAGE.DETAIL);
    }
  }
})
export default class TabsCountry extends Vue {
  // Breadcrumb
  breadcrumb = [];

  // Search
  search = "";
  onSearch(value) {
    LocationController.searchCountry(value);
    this.search = value;
    this.firstPage();
  }

  clearSearch() {
    LocationController.clearSearchCountry();
    this.firstPage();
    this.search = "";
  }

  // Filter
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];
  statusSelect = false;
  statusName = "";

  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name, value) {
    this.onCloseStatusSelect();
    LocationController.selectStatusCountry(value);
    this.firstPage();
    this.statusName = name;
  }

  // Header
  columns = [
    {
      name: "Country ID",
      key: "id",
      styleHead: "w-1/12 text-center whitespace-no-wrap",
      render: item => {
        return `<span class='text-red-lp-100 flex justify-center'>${item.id}</span>`;
      }
    },
    {
      name: "Country Code",
      key: "countryCode",
      styleHead: "w-3/12 text-center",
      render: item => {
        return `<div class='flex justify-center'><div class='rounded px-2 py-0 bg-gray-lp-400 text-black-lp-300'>${item.code}</div></div>`;
      }
    },
    {
      name: "Country Name",
      key: "countryName",
      styleHead: "w-3/12 text-left",
      render: item => {
        return `<div class='text-black-lp-300'>${item.name}</div>`;
      }
    },
    {
      name: "Currency",
      key: "currency",
      styleHead: "w-2/12 text-left",
      render: item => {
        return `<div class='text-black-lp-300'>${item.currency}</div>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-2/12 text-left",
      render: item => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${
          item.status === "active" ? "bg-green-lp-300" : "bg-red-300"
        } ${item.status === "active" ? "text-green-lp-200" : "text-red-lp-200"}"
      ><span class="capitalize">${item.status}</span</div></div>`;
      }
    }
  ];

  // get data country list
  created() {
    LocationController.selectStatusCountries("");
    LocationController.setSearchCountry("");
    this.getListData();
    this.firstPage();
  }

  getListData() {
    LocationController.getCountryList({
      search: "",
      status: "",
      page: 1,
      limit: 10
    });
  }

  //Loading
  get isLoading() {
    return LocationController.isLoadingCountry;
  }

  get countryListData() {
    return LocationController.countryData.countryData;
  }

  firstPage() {
    return LocationController.setFirstCountryPage();
  }

  // To Detail
  onClickRow(item) {
    this.breadcrumb.push(item.id);
    router.push(`/network/location/country/${item.id}`);
  }

  // Pagination
  get page() {
    return LocationController.pageCountry;
  }
  get limit() {
    return LocationController.limit;
  }
  nextPage() {
    LocationController.setNextPageCountry();
  }

  prevPage() {
    LocationController.setPrevPageCountry();
  }

  toPage = debounce(value => {
    LocationController.setPageCountryAction(value);
  }, 500);

  //Error
  get isError() {
    return LocationController.isError;
  }

  //Error Cause
  get errorCause() {
    return LocationController.isErrorCauseCountry;
  }
}
</script>
